@import '../../../Global';

.agreement-box {
    position: relative;
        margin: 1rem 0;
        padding: 2rem;
        box-sizing: border-box;
        background-color: #ffffd9;
        &:last-child {
            margin-bottom: 2rem;
        }
        @include media("<tablet") {
            padding: 1rem;
        }
    input {
        position: absolute;
    left: 1.8rem;
    top: 2rem;
    width: 20px;
    height: 20px;
        @include media("<tablet") {
            top: 1rem;
            left: 0.75rem;
        }
    }

    label {
        display: block;
        margin-left: 2.6rem;
        cursor: pointer;
        white-space: pre-wrap;
        &:after {
            content: '';
        }
        @include media("<tablet") {
            font-size: $smallFont;
            margin-left: 2.2rem;
        }
    }
}