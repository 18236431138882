@import '../../../Global';

.table {
    display: block;
    margin: 3rem 0;
    h2 {
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        @include media("<tablet") {
            font-size: 1.2rem;
        }
    }
    table {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        width: 100%;
        margin: 0 auto;
        border-spacing: 0;
        margin-bottom: 2rem;
        th, td {
            padding: 0.5rem;
            width: 50%;
            text-align: left;
            white-space: pre-line;
            word-break: break-word;
            @include media("<tablet") {
                font-size: $smallFont;
            }
        }
        td {
            &:last-child {
                text-align: left;
            }
        }
        th {
            &:first-child {
                text-align: left;
            }
            border-bottom: 1px solid #ccc;
        }
        tr {
            &:nth-child(even) {
                background: #f6f6f6
            }
            &:nth-child(odd) {
                background: #FFF
            }
        }
    }
}