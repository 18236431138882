@import '../../../Global';

.account-selection {
    position: relative;
    background-color: #eef7ee;
    padding: 2rem;
    border-radius: 0.5rem;
    @include media("<tablet") {
          padding: 1rem;
        }
    h2 {
        font-weight: 500;
        font-size: 1rem;
        margin: 0;
        color: #5a805a;
        margin-right: 100px;
    }
    .account-name {
        font-size: 1.6rem;
        margin: 0.4rem 0;
        display: block;
         margin-right: 100px;
         @include media("<tablet") {
           font-size: 1.3rem;
        }
    }
    .verify-image {
        position: absolute;
    right: 40px;
    top: 40px;
    bottom: 0;
    width: 130px;
    opacity: 0.5;
    @include media("<tablet") {
           width: 100px;
           right: 1rem;
           top: 1rem;
        }
    }
    .account-mask {
font-size: 1rem;
display: block;
 margin-right: 100px;
    }
    button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0);
    padding: 0;
    padding: 0.25rem 0.5rem 0.35rem;
    line-height: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #333;
    color: #333;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 0.8rem;
    transition: background-color 0.25s ease-in-out;
    &:hover {
        background-color: rgba(255, 255, 255, 0.609)
    }
    }
}