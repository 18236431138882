@import '../../Global';

.step {
    .button-bar {
        overflow: hidden;
        margin: 2rem 0;
        border-top: 1px solid #ccc;
        padding: 2rem 0;
        @include media("<tablet") {
            padding: 1rem 0;
            margin: 1rem 0 0;
        }
        .next-button {
            
            background-color: #00559C;
            color: #fff;
            border: 0px;
            border-radius: 99px;
            float: right;
            padding: 15px 20px;
            box-sizing: border-box;
            font-size: 1.2rem;
            min-width: 200px;
            text-transform: uppercase;
            transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
            box-sizing: border-box;
            font-weight: 700;
            cursor: pointer;
            @include media("<tablet") {
                line-height: 45px;
                padding: 0 1rem;
                min-width: 160px;
                font-size: 1rem;
            }
            &:hover {
                background-color: #2c455a;
            }
            &.disabled {
                opacity: 0.35;
            }

        }
        .previous-button {
            cursor: pointer;
            background-color: transparent;
            font-size: 1rem;
            color: #222;
            border: 0px;
            line-height: 60px;
            font-size: 1.2rem;
            @include media("<tablet") {
                line-height: 45px;
                font-size: 1rem;
            }
        }
    }
}

button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
}