.field-set-expandable-list {
    .add-button {
        background-color: #5f88ab;
    color: #fff;
    border: 0px;
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    box-sizing: border-box;
    line-height: 60px;
    font-size: 1.2rem;
    min-width: 200px;
    text-transform: uppercase;
    transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
    padding-bottom: 0.2rem;
    cursor: pointer;
    margin-bottom: 2rem;
    }

    .expandable-wrapper {
        position: relative;
        .remove-button {
            position: absolute;
            right: 1.5rem;
            top: 1rem;
            background-color: #FFF;
            color: #777;
            border: 1px solid #777;
            border-radius: 0.5rem;
            padding: 0 1.5rem;
            box-sizing: border-box;
            line-height: 40px;
            font-size: 1rem;
            min-width: 130px;
            text-transform: uppercase;
            transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
            padding-bottom: 0.2rem;
            cursor: pointer;
            
    
        }
    }
}