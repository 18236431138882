@import '../../../Global';

.document-download {
    margin: 2rem 0;
    /* margin-right: 20%; */
    padding-right: 40%;
    position: relative;
    @include media("<tablet") {
        padding-right: inherit;
        font-size: $smallFont;
    }
    a {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        /* padding: 1rem; */
        /* background-color: #68a2d3; */
        border-radius: 0.5rem;
        @include media("<tablet") {
            position: relative;
            top: auto;
            transform: none;
            margin: 1rem 0;
        }
        img {
            width: 32px;
            vertical-align: middle;
            margin-right: 12px;
        }
    }
}