@import '../../Global';

.form-section {
    .form-section-header {
        margin-bottom: 3rem;
        @include media("<tablet") {
            margin-bottom: 2rem;
          }
    }
    h1 {
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        font-size: 2rem;
        padding-bottom: 1rem;
         margin-top: 0.5rem;
         @include media("<tablet") {
            font-size: 1.5rem;
            padding-bottom: 0.5rem;
          }
    }
    p {
        font-size: 1.1rem;
        white-space: pre-line;
        @include media("<tablet") {
            font-size: $smallFont;
          }
    }
}