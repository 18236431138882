@import '../../../Global';

.standard-input {
    overflow: hidden;
    margin: 0 0 1rem;
    position: relative;
    &:last-child {
            margin-bottom: 2rem;
        }
    label {
        position: relative;
        font-size: 1.1rem;
        width: 35%;
        display: inline-block;
        @include media("<tablet") {
            font-size: 1rem;
            width: auto;
            display: block;
            margin-bottom: 0.5rem;
        }
        &.dollar {
            &:after {
                content: '$';
                right: 0;
                top: 5px;
                position: absolute;
                @include media("<tablet") {
                    right: inherit;
                    top: 38px;
                    left: 10px;
                }
            }
        }
        &.hidden {
            opacity: 0;
            @include media("<tablet") {
                height: 0;
            }
        }
        &.override {
            width: 66%;
            float:right;
            line-height: 36px;
            padding-left: 0.7rem;
                box-sizing: border-box;
            @include media("<tablet") {
                width: 100%;
                display: block;
                float: none;
                
                padding-left: 0;
            }

            input {
                float: none;
                width: auto;
                line-height: 36px;
                margin-right: 0.5rem;
                
                @include media("<tablet") {
                    float: left;
                    margin-right: 10px;
                    margin-top: 11px;
                }
            }
        }
    }
    label.dollar + input {
        @include media("<tablet") {
        text-indent: 1rem;
        }
    }
    input {
        width: 64%;
        float:right;
        box-sizing: border-box;
        padding: 0.5rem;
        //height: 36px;
        font-size: 1rem;
        font-family: $standardFont;
        border: 1px solid #ccc;
                &:focus {
                    border: 1px solid #333;
                }
        @include media("<tablet") {
            width: 100%;
            display: block;
            float: none;
        }
        &:focus {
            outline: none;
            
        }
        
    }
    textarea {
        width: 64%;
        float:right;
        box-sizing: border-box;
        padding: 0.5rem;
        height: 150px;
        font-size: 1rem;
        border: 1px solid #ccc;
        &:focus {
            outline: none;
            border: 1px solid #333;
        }
        
                
        @include media("<tablet") {
            width: 100%;
            display: block;
            float: none;
        }
    }
    .char-count {
        position: absolute;
        right: 16px;
        bottom: 9px;
    }
    &.error {
        label {
            color: red;
        }
        input {
            border: 2px solid red;
            background-color: #fff5f5;;
            &:focus {
                border: 2px solid red;
            }
        }
        strong {
            width: 64%;
            float: right;
            box-sizing: border-box;
            padding: 0.5rem;
            //height: 36px;
            font-size: 1rem;
            background-color: red;
            color: #fff;
            font-weight: 500;
            padding-top: 0.4rem;
            @include media("<tablet") {
                width: 100%;
                display: block;
                margin-bottom: 0.5rem;
            }
        }
        
    }
    .required {
        font-weight: bold;
        color: red;
        text-indent: 0.3rem;
        display: inline-block;
    }
    .hint {
        margin-left: 35%;
        display: block;
        margin-top: 0;
        padding: 0.5rem 0.5rem;
        box-sizing: border-box;
        clear: both;
        font-style: italic;
        @include media("<tablet") {
                margin-left:auto;
            }
    }
    
}