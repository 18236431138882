@import '../../Global';

.church-investors {
    height: 100%;
    box-sizing: border-box;
    h1 {
        font-size: 1.8rem;
    font-weight: 400;
    margin: 0;
    line-height: 100px;
    padding: 0 2rem;
     @include media("<tablet") {
         line-height: 76px;
            font-size: 1.2rem;
        }
    }
    .scroll-wrapper {
        
        position: absolute;
    top: 100px;
    bottom: 100px;
    left: 0;
    right: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @include media("<tablet") {
         top: 76px;
         bottom: 76px;
        }
    }
    .scroll-area {
        padding: 2rem;
        box-sizing: border-box;
        overflow: scroll;
        overflow-x: hidden;
    /* height: 100%; */
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        @include media("<tablet") {
          padding:1rem 2rem;
        }
    }
}
