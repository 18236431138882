@import '../../../Global';

.big-vertical-button {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    position: relative;
    padding: 1rem;
    padding-right: 4rem;
    box-sizing: border-box;
    box-shadow: 0px 2px 18px -17px #000;
    transition: transform 0.15s ease-in, box-shadow 0.15s ease-in;
    transform: translate3d(0px, 0px, 0);
    cursor:pointer;
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        .check-box {
            display: none;
        }
    }
    &.hover {
        background-color: #f6fbff;
        transform: translate3d(0, -3px, 0);
        box-shadow: 0px 5px 19px -17px #000;
        &.selected {
            background-color: #ebf6ff;
        }
    }
    
    input {
        position: absolute;
        opacity: 0;
        
    }
    label {
        cursor:pointer;
        font-size: 1.4rem;
        @include media("<tablet") {
            font-size: 1.2rem;
        }
    }
    .sub-label {
        width: 50%;
        font-size: 1rem;
        display: block;
        cursor:pointer;
        @include media("<tablet") {
            font-size: 0.9rem;
        }
    }
    .extra-info, .disabled-info {
        position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
        @include media("<tablet") {
            position:relative;
            transform: none;
            top: inherit;
            right: auto;
            font-size: 1rem;
        }
    }
    .check-box {
        position: absolute;
        right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    }
    &.selected {
        background-color: #ebf6ff;
    }
    .special {
        text-transform: uppercase;
        font-size: 0.8rem;
        background-color: #00559cb5;
        color: #fff;
        padding: 0.25rem 0.6rem;
        border-radius: 0.5rem;
        letter-spacing: 0.1rem;
        
        display: inline-block;
        @include media("<tablet") {
            margin-bottom: 0.2rem;
        }
    }
}