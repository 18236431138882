@import '../../../Global';

.alert {
    position: relative;
    margin: 1rem 0;
    padding: 2rem;
    box-sizing: border-box;
    background-color: #ffffd9;
    &:last-child {
            margin-bottom: 2rem;
        }
        @include media("<tablet") {
            padding: 1rem;
        }
}