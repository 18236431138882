@import '../../../Global';

.big-vertical-buttons {
    margin: 0 0 2rem;
    ul {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
        @include media("<tablet") {
            column-gap: 1rem;
            row-gap: 1rem;
        }
    }
    .big-vertical-button-group {
        h2 {
            font-weight: 400;
            font-size: 1.4rem;
        }
        ul {
            row-gap: 0;
            border: 1px solid #ccc;
            border-radius: 0.5rem;
            overflow: hidden;
            
        }
        .big-vertical-button {
            box-shadow: none;
            border: 0;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
            transition: none;
            &.hover {
                transform: translate3d(0, 0, 0);
                box-shadow: none;
                
            }
        }
        li {
            &:last-child {
                .big-vertical-button {
                    
                    
                            border-bottom: 0;
                        
                    
                }
            }
        }
    }
    .footnote {
        font-style: italic;
        text-align: center;
    }
}