@import '../../../Global';

.field-set {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    padding: 1.5rem;
    &:last-child {
            margin-bottom: 2rem;
        }
    h2 {
        margin-top: 0;
        font-weight: 400;
        font-size: 1.4rem;
        margin-bottom: 1.4rem;
        @include media("<tablet") {
            font-size: 1.2rem;
        }
    }
}