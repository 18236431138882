@import '../../../Global';


.send-summary {
    &.gray {
        background-color: #f6f6f6;
        .email-user {
            background-color: #ededed;
        }
    }
    h2 {
        font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 1rem;
        @include media("<tablet") {
            font-size: 1.2rem;
        }
    }
    margin-bottom: 3rem;
    background-color: #ffffd9;
    &.steps {
        background-color: transparent !important;
        padding-left: 1.5rem;
    }
    ol {
        margin: 0;
        padding: 0;
        font-size: 1.4rem;
        @include media("<tablet") {
            font-size: 1.2rem;
        }
        li {
            margin-bottom: 2rem;
        }
    }
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    @include media("<tablet") {
            padding: 0.5rem 1rem;
        }
    .email-user {
        span {
            display: block;
        }
        position: relative;
        margin: 1rem 0;
        padding: 1rem;
        box-sizing: border-box;
        background-color: #f4f4c7;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include media("<tablet") {
            grid-template-columns: 1fr;
        }
    }
    .email-user-name {
        font-size: 1.2rem;
        width: 45%;
        display: block;
        @include media("<tablet") {
            
            width: 100%;
            position: relative;
            top: inherit;
            
        }
    }
    .email-user-title {
        font-style: italic;
        font-size: 1rem;
        width: 45%;
        display: block;
        order: 3;
        @include media("<tablet") {
            order: 2;
            float: none;
            width: 100%;
            position: relative;
            top: inherit;
            
        }
    }
    .email-user-email {
        //position: absolute;
        //right: 0;
        top: 1rem;
        font-size: 1.2rem;
        //float: right;
        //width: 50%;
        grid-row: span 2;
        order: 2;
        @include media("<tablet") {
            order: 3;
            //float: none;
            //width: 100%;
            word-break: break-all;
            font-size: 1rem;
            position: relative;
            top: inherit;
            
        }
    }
    p {
        font-size: 1.1rem;
        @include media("<tablet") {
            font-size: 1rem;
            
        }
    }
}

