@import '../../../Global';



.big-image-button {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    position: relative;
    box-shadow: 0px 2px 18px -17px #000;
    transition: transform 0.15s ease-in, box-shadow 0.15s ease-in;
    transform: translate3d(0px, 0px, 0);
    cursor:pointer;
    &.hover {
        transform: translate3d(0, -3px, 0);
        box-shadow: 0px 5px 19px -17px #000;
    }
    input {
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }
    .image-frame {
        height: 0;
        padding-top: 62%;
        background-color: #f5f5f5;
        position: relative;
        opacity: 0.7;
        overflow:hidden;
        border-radius: 0.5rem 0.5rem 0 0 ;
        img {
            width: 100%;
            position: absolute;
            top:0;
            left: 0;
        }
        &:after {
            content:'';
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            box-shadow: inset 0 0 35px -28px #000;
        }
    }
    label {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        padding: 1rem;
        box-sizing: border-box;
        cursor:pointer;
        @include media("<tablet") {
            padding: 0.5rem;
            font-size: 1rem;
        }
    }
    .check-box {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}