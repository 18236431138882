@import '../../../Global';

.rate-chart {
    margin: 2.5rem 0 3.5rem;
    @include media("<tablet") {
        margin: 1.5rem 0 1.5rem;
    }
    table {
        width: 80%;
        margin: 0 auto;
        border-spacing: 0;
        border-bottom: 1px solid #ccc;
        @include media("<tablet") {
            width: 100%;
        }
        th, td {
            padding: 0.8rem;
            
        }
        td {
            &:last-child {
                text-align: center;
            }
        }
        th {
            &:first-child {
                text-align: left;
            }
            border-bottom: 1px solid #ccc;
        }
        tr {
            &:nth-child(even) {
                background: #f6f6f6
            }
            &:nth-child(odd) {
                background: #FFF
            }
        }
    }
    .foot-note {
        width: 80%;
        margin: 0 auto;
        padding: 1rem;
        box-sizing: border-box;
        font-style: italic;
        @include media("<tablet") {
            width: 100%;
        }
    }
}