h1 {
    font-size: 50px;
}
.check-box {
    .check-square {
        width: 36px;
        height: 36px;
        
        transition: background-color 0.2s ease-in;
        &:after {
            opacity: 0;
            transition: opacity 0.2s ease-in;
            content: '';
                background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='402' width='582' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cline stroke-width='2px' stroke-linejoin='round' stroke-linecap='round' stroke='%23ffffff' fill='none' id='svg_1' y2='25' y1='16' x2='12' x1='3' class='cls-1'/%3E%3Cline stroke-width='2px' stroke-linejoin='round' stroke-linecap='round' stroke='%23ffffff' fill='none' id='svg_2' y2='7' y1='25' x2='29' x1='12' class='cls-1'/%3E%3C/g%3E%3C/svg%3E");
                background-size: 100%;
                width: 28px;
                height: 28px;
                display: block;
                position: absolute;
                top: 4px;
                left: 4px;
        }
        
        &.checked {
            background-color: #00559c;
            border-radius: 0.5rem;
            box-shadow: inset 4px 4px 7px -6px #000;
            &:after {
              opacity: 1;       
            }
        }
        &.unchecked {
            
            background-color: #eaeaea;
    border-radius: 0.5rem;
    box-shadow: inset 4px 4px 7px -6px #000;
            &:after {
                opacity: 0;    
            }
        }
    }
    
}