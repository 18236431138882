@import '../../Global';

.modal-overlay {
    
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 1000;
    iframe {
        width: 100%;
    display: block;
    box-sizing: border-box;
    border: 0;
    
    }
    .modal-overlay-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.7;
    }
    .modal-content {
        box-shadow: 0 0 18px -6px #000;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 100%;
    background-color: #fff;
    position: relative;
    .modal-footer {
        position: absolute;
        bottom: 0;
        height: 100px;
        width: 100%;
        text-align: right;
        @include media("<tablet") {
                height: 50px;
            }
        button {
            margin-right: 1rem;
            margin-top: 18px;
            background-color: #5f88ab;
            color: #fff;
            border: 0px;
            border-radius: 0.5rem;
            float: right;
            padding: 0 1.5rem;
            box-sizing: border-box;
            line-height: 60px;
            font-size: 1.2rem;
            min-width: 200px;
            text-transform: uppercase;
            transition: background-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
            padding-bottom: 0.2rem;
            cursor: pointer;
            @include media("<tablet") {
                line-height: 45px;
                padding: 0 1rem;
                min-width: 160px;
                font-size: 1rem;
                margin-top: -10px;
            }
            &:hover {
                background-color: #2c455a;
            }
            &:disabled {
                opacity: 0.35;
            }
        }
    }
    }
    .modal-wrapper {
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: 4rem;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        /* right: auto; */
        max-width: 900px;
        z-index: 1001;
        /* min-height: 700px; */
        position: absolute;
        box-sizing: border-box;
        padding: 0 2rem;
        @include media("<tablet") {
            top: 1rem;
        bottom: 1rem;
            padding: 0 1rem;
        }
    }
    .modal-close {
        position: absolute;
        right: 0;
        top: 0;
        text-indent: -9999px;
        &:after {
            cursor:pointer;
            text-indent: 0px;
            content: 'X';
            background-color: #000;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 100%;
            width: 32px;
            height: 32px;
            text-align: center;
            position: absolute;
            line-height: 32px;
            right: 13px;
            box-shadow: 0 0 8px -2px #000;
            top: -18px;
        }
    }
    .small {
        &.modal-wrapper {
            bottom: auto;
        }
        .scroll-wrapper {
            position: relative;
            top: auto;
            bottom: auto;
        }
        .scroll-area {
            position: relative;
        }
        .modal-footer {
            position: relative;
        }
    }
    
}