@import '../../../Global';

.big-image-buttons {
    &:last-child {
            margin-bottom: 2rem;
        }
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
        li {
            

        }
        @include media("<tablet") {
            column-gap: 1rem;
            row-gap: 1rem;
        }
    }
}