@import '../../../Global';

.standard-check {
    margin-left: 35%;
    margin-bottom: 1rem;
    @include media("<tablet") {
        margin-left: 0;
    }
    input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        @include media("<tablet") {
            margin-left: 0;
        }
    }
    label {
        font-size: 1.1rem;
        cursor: pointer;
        @include media("<tablet") {
            font-size: 1rem;
        }
    }
}