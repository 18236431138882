@import '../../Global';

$progress-color: #5f88ab;

.progress-track {
    padding-top: 1rem;
    margin-bottom: 4rem;
    font-size: 0.85rem;
    @include media("<=desktop") {
        font-size: 0.7rem;
    }
    @include media("<tablet") {
        display: none;
    }
    &.finished {
        pointer-events: none;
        .progress-step {
            color: rgb(105, 105, 105) !important;
            &.current-step {
                font-weight: normal;
            }
            &.max-step {
                &:before {
                    background: #31ac4a;
                    
                }
            }
        }
    }
    ul {
        display: flex;
        flex-direction: row;
        li {
            
            flex-grow: 1;
            flex-basis: 0;
            position: relative;
            .progress-label {
                padding: 1.5rem 0;
                display: block;
            }
            &:after {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background-color: #00559c;
            }
            &:before {
                border: 4px solid #fff;
                z-index: 1;
                content: '';
                position: absolute;
                left: 50%;
                bottom: -12px;
                transform: translateX(-50%);
                border-radius: 100%;
                width: 20px;
                height: 20px;
                background-color: #00559c;
            }
            &:first-child {
                &:after {
                    right: 0;
                    left: auto;
                    width: 50%;
                }
            }
            &:last-child {
                &:after {
                    
                }
            }
        }
    }

    .progress-step {
        text-align: center;
        &.max-step {
           
            &:after {
                background: linear-gradient(90deg, $progress-color 50%, #cccccc 50%);
            }
            &:before {
                background: radial-gradient(#fff 50%, $progress-color 50%);
            }
            &:first-child {
                &:after {
                    background: #cccccc;
                }
            }
            &:last-child {
                &:after {
                    background: linear-gradient(90deg, $progress-color 50%, #fff 50%);
                }
            }
            &.error {
                &:before {
                    background: radial-gradient(#fff 50%, $progress-color 50%);
                }
            }
        }
        &.current-step {
            font-weight: bold;
            &:after {
                
            }
            &:before {
                
            }
        }
        &.unlocked-step {
            cursor: pointer;
            color: #333;
            &:after {
                background-color: $progress-color;
            }
            &:before {
                background-color: #31ac4a;
                content: '\2713';
                text-indent: -1px;
                color: #fff;
            }
        }
        &.locked-step {
            color: #cccccc;
            &:after {
                background-color: #cccccc;
            }
            &:before {
                background-color: #cccccc;
            }
            &:last-child {
                &:after {
                    background: linear-gradient(90deg, #cccccc 50%, #fff 50%);
                }
            }
        }
        &.error {
            &:before {
                
                background: #d50000;
                content: 'X';
                text-indent: 0px;
                font-size: 14px;
                color: #fff;
            }
        }
    }
}